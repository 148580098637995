<template>
  <div style="padding-bottom: 100px" class="updatePassword">
<!--    <rxNavBar title="shujv" androidOrIOSFlag="true" style="background-color: red" backColor="true"></rxNavBar>-->
    <!-- <rxNavBar androidOrIOSFlag="true" title="数据菜单"></rxNavBar> -->
    <!-- <div style="height:15px;width:100%;background:white"></div> -->

    <div class="img_group">
            <img :src="headImg" class="oneImg">
            <div class="setPsd">数据菜单</div>
    </div>

    <div class="appointmentDetail">
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(0)" v-if="tempAuth0">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg1" class="detail-img"></div>
        <span class="houseRelevantText">业务统计</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(11)" v-if="tempAuth11">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg10" class="detail-img"></div>
        <span class="houseRelevantText">目标达成</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(1)" v-if="tempAuth1">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg4" class="detail-img"></div>
        <span class="houseRelevantText">集寓报表</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(2)" v-if="tempAuth2">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg7" class="detail-img"></div>
        <span class="houseRelevantText">基础人事</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(3)" v-if="tempAuth3">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg8" class="detail-img"></div>
        <span class="houseRelevantText">财务统计</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(9)" v-if="tempAuth9">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg1" class="detail-img"></div>
        <span class="houseRelevantText">资金统计</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(10)" v-if="tempAuth10">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg8" class="detail-img"></div>
        <span class="houseRelevantText">现金流量</span>
      </div>

      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(4)" v-if="tempAuth4">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg2" class="detail-img"></div>
        <span class="houseRelevantText">装修统计</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(5)" v-if="tempAuth5">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg5" class="detail-img"></div>
        <span class="houseRelevantText">租后统计</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(6)" v-if="tempAuth6">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg9" class="detail-img"></div>
        <span class="houseRelevantText">客户服务</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(7)" v-if="tempAuth7">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg3" class="detail-img"></div>
        <span class="houseRelevantText">业绩报表</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(8)" v-if="tempAuth8">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">用户画像</span>
      </div>
      
      
      <!--<div class="relevvant-detail relevvant-detail-border" @click="businessManual">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">业务类手册</span>
      </div>-->
    </div>
    <!-- <rxTabbar checked="dataMenu"></rxTabbar> -->
  </div>
</template>

<script>
import {
  Button, Calendar, DatetimePicker, NavBar, NumberKeyboard, Popup, Tabbar, TabbarItem, Toast
} from 'vant'
import rxTabbar from '../../components/rongxun/rx-tabbar/tabbar'
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import {
  checkAndroid,
  checkAuthList,
  checkIOS,
  getStaffId,
  globaluserId,
  openInWebview,
  responseUtil
} from "../../libs/rongxunUtil";
import {getStaffFuntionModelList, queryMyPageInit,userAuthList} from "../../getData/getData";
function backToAndroid(){
  window.himi.closeWindow();
}
export default {
  name: "dataMenu",
  components : {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
    rxTabbar
  },
  data (){
    return{
      tempAuth0: false, //业务统计
      tempAuth1: false, //集寓报表
      tempAuth2: false, //基础人事
      tempAuth3: false, //财务统计
      tempAuth4: false, //装修统计
      tempAuth5: false, //租后统计
      tempAuth6: false, //客户服务
      tempAuth7: false, //业绩
      tempAuth8: false, //用户画像
      tempAuth9: false, //资金统计
      tempAuth10:false, //现金流量
      tempAuth11:false, //目标
      active:5,
      userName:'',
      number:'617828',
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
      headImg: require("../../assets/images/head-quotation-white.png"),
      houseRelevantRenterImg: require("../../assets/images/house-relevant-renter.png"),  // 租客
      houseRelevantRenterImg1: require("../../assets/images/house-relevant-cut.png"),  // 租客
      houseRelevantRenterImg2: require("../../assets/images/house-relevant-offer.png"),  // 租客
      houseRelevantRenterImg3: require("../../assets/images/house-relevant-owner.png"),  // 租客
      houseRelevantRenterImg4: require("../../assets/images/house-relevant-take-lock.png"),  // 租客
      houseRelevantRenterImg5: require("../../assets/images/house-relevant-upload.png"),  // 租客
      houseRelevantRenterImg6: require("../../assets/images/mapt.png"),  // 租客
      houseRelevantRenterImg7: require("../../assets/images/img_jichurenshi1.png"),  // 租客
      houseRelevantRenterImg8: require("../../assets/images/img_caiwu1.png"),  // 租客
      houseRelevantRenterImg9: require("../../assets/images/img_kehufuwu1.png"),  // 租客
      houseRelevantRenterImg10: require("../../assets/images/mubiaotongji.png"),  // 租客
      authButtonsList:[],
    }
  },
  mounted() {
    this.getStaffFuntionModelList()
    this.queryMyPageInit()
    this.getStaffIdEvent()
    // window.webkit.messageHandlers.setTabbarHidden.postMessage({isHidden: false });
  },
  destroyed() {
    // window.webkit.messageHandlers.setTabbarHidden.postMessage({isHidden: true });
  },
  methods: {
     getStaffIdEvent() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId()

      userAuthList(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.data
          //将对象转为string
          var result = JSON.stringify(list)
          if (result.indexOf('dataService_index') != -1) {
            that.tempAuth0 = true
          }
          if (result.indexOf('concentrateDapartment_index') != -1) {
            that.tempAuth1 = true
          }
          if (result.indexOf('staffDataStatistics_index') != -1) {
            that.tempAuth2 = true
          }
          if (result.indexOf('financeManage_index') != -1) {
            that.tempAuth3 = true
          }
          if (result.indexOf('decorationData_index') != -1) {
            that.tempAuth4 = true
          }
          if (result.indexOf('AfterRentDataStatistics_index') != -1) {
            that.tempAuth5 = true
          }
          if (result.indexOf('clientServeDataStatistics_index') != -1) {
            that.tempAuth6 = true
          }
          if (result.indexOf('performanceReport_index') != -1) {
            that.tempAuth7 = true
          }
          if (result.indexOf('userPortrait_index') != -1) {
            that.tempAuth8 = true
          }
          if (result.indexOf('fundStatistics_index') != -1) {
            that.tempAuth9 = true
          }
          if(result.indexOf('cashFlow_index') != -1) {
            that.tempAuth10 = true
          }
          if(result.indexOf('target_index') != -1) {
            that.tempAuth11 = true
          }
        })
      })
    },
    clickEventInfo(num) {
      window.webkit.messageHandlers.openWebPage.postMessage({url: "financialModule?dataType=" + num });
      // this.$router.push({
      //   name: 'financialModule',
      //   query: {
      //     dataType: num
      //   }
      // })

    },
    //跳转租客合同
    skipRenterContract(){
      this.$router.push({
        name: 'portApproveList',
        query: {
          fromType:'0',
          type: 1,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    //修改工资卡
    alertPayCard(){
      this.$router.push({
        name: 'staffPayCard',
        query: {

        }
      })
    },
    //房源列表
    houseList(){
      this.$router.push({
        name: 'houseList',
        query: {

        }
      })
    },
    //工资审批
    salaryApplication(){
      this.$router.push({
        name: 'salaryApproval',
        query: {

        }
      })
    },
    //通讯录
    addressBook(){
      this.$router.push({
        name: 'addressBook',
        query: {

        }
      })
    },
    //业务类手册
    businessManual(){
      this.$router.push({
        name: 'businessManual',
        query: {

        }
      })
    },
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    //权限方法
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'dataStatistics'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
          console.log('that.authButtonsList==',that.authButtonsList)
        })
      })
    },
    queryMyPageInit () {
      var that = this
      let data = {}
      data.user_id = globaluserId()
      queryMyPageInit(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.userName)
          that.userName = response.data.data.userName
        })
      })
    },
    checkAuthList,
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

.changeStatusStyle {
  height: 2rem;
}
.confirmCancelOrdersText {
  margin-left: 35px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: bold
}

.cancelOrdersImg {
  float: left;
  margin: 20px 5px 0 15px;
  width: 15px;
  height: 15px;
}
.cnacelOrdersText {
  margin-top: 18px;
  font-size: 15px;
  font-weight: bold
}

.relevvant-detail {
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  padding:25px 0px 29px 0px;
  /* margin-top: 10px; */
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
  white-space: nowrap;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}


</style>